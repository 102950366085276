import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, NgModule } from '@angular/core';
import amplitude from 'amplitude-js';
import { ReplaySubject, BehaviorSubject, timer } from 'rxjs';
import { switchMap, filter, take } from 'rxjs/operators';
import * as i1 from '@aviatar/sso';
const USER_TRACKING_CONFIG = new InjectionToken('USER_TRACKING_CONFIG');
const INTERNAL_GROUP_PREFIX = 'AVIATAR';
const CUSTOMER_GROUP_PREFIX = 'customer';
/**
 * I have to reassign 'amplitude' because the build optimizer changes
 * "import * as amplitude from 'amplitude-js/amplitude';"
 * to
 * "import { getInstance } from 'amplitude-js/amplitude';"
 * which does not work because Amplitude's "this"-reference is lost and
 * the frontend throws a 'Cannot read property _instances of undefined' error.
 */
const amplitudeService = amplitude;
const AMPLITUDE_SESSION_TIME = 1000 * 60 * 10;
/**
 * Screen information to be attached as custom user properties
 */
const screenInformation = {
  screenResolution: screen.width + 'px x ' + screen.height + 'px',
  screenResolutionHeight: screen.height,
  screenResolutionWidth: screen.width,
  viewport: window.innerWidth + 'px x ' + window.innerHeight + 'px',
  viewportHeight: window.innerHeight,
  viewportWidth: window.innerWidth
};
class UserTrackingService {
  constructor(keycloakService, userTrackingConfig) {
    this.keycloakService = keycloakService;
    this.userTrackingConfig = userTrackingConfig;
    this.events$ = new ReplaySubject();
    this.initialized$ = new BehaviorSubject(false);
    if (this.userTrackingConfig.enabled) {
      if (!userTrackingConfig.amplitudeApiKey) {
        throw new Error('User Tracking Config Error: amplitudeApiKey not provided.');
      }
      if (!userTrackingConfig.appName) {
        throw new Error('User Tracking Config Error: appName not provided.');
      }
      if (!userTrackingConfig.stage) {
        throw new Error('User Tracking Config Error: stage not provided.');
      }
      //Refresh amplitude session
      timer(0, AMPLITUDE_SESSION_TIME).pipe(switchMap(_ => this.keycloakService.getUser().pipe(filter(user => !!user), take(1)))).subscribe(ssoUser => {
        amplitudeService.getInstance().init(this.userTrackingConfig.amplitudeApiKey, ssoUser.id, {
          apiEndpoint: this.userTrackingConfig.amplitudeApiEndpoint || 'statistics.apps.aviatar.io',
          trackingOptions: {
            ip_address: false
          }
        }, () => this.setUserProperties(ssoUser), ssoUser);
        this.initialized$.next(true);
      });
      // wait for amplitude initialization and send events
      this.initialized$.pipe(filter(isInitialized => isInitialized), take(1), switchMap(_ => this.events$)).subscribe(event => amplitudeService.getInstance().logEvent(event.name, event.payload));
    }
  }
  sendEvent(event) {
    if (this.userTrackingConfig.enabled) {
      const payload = {
        action: event.action,
        source: {
          app: this.userTrackingConfig.appName,
          stage: this.userTrackingConfig.stage,
          url: window.location.href
        }
      };
      if (event.customDetails) {
        payload.custom_details = event.customDetails;
      }
      this.events$.next({
        name: event.name,
        payload: payload
      });
    }
  }
  setUserProperties(user) {
    let isInternal = false;
    let customerOf = [];
    // this is a hack and might break due to the fact that userInfo.group_membership is not public
    const groups = user.userInfo.group_membership;
    if (groups && groups.length > 0) {
      const groupPrefixes = groups.map(group => group.split('/')[1]); // empty string at index 0
      const seen = {};
      const len = groupPrefixes.length;
      let j = 0;
      for (var i = 0; i < len; i++) {
        const prefix = groupPrefixes[i];
        if (seen[prefix] !== 1) {
          seen[prefix] = 1;
          // User is internal if s/he is in at least one group starting with 'AVIATAR'
          if (prefix.indexOf(INTERNAL_GROUP_PREFIX) === 0) {
            isInternal = true;
          }
          if (prefix.indexOf(CUSTOMER_GROUP_PREFIX) === 0) {
            customerOf[j++] = prefix.split('-')[1];
          }
        }
      }
    }
    const userProperties = {
      partnerId: user.primaryPartnerId,
      internal: isInternal,
      customerOf,
      ...screenInformation
    };
    amplitudeService.getInstance().setUserProperties(userProperties);
  }
  static {
    this.ɵfac = function UserTrackingService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || UserTrackingService)(i0.ɵɵinject(i1.KeycloakService), i0.ɵɵinject(USER_TRACKING_CONFIG));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserTrackingService,
      factory: UserTrackingService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserTrackingService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.KeycloakService
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [USER_TRACKING_CONFIG]
    }]
  }], null);
})();
class UserTrackingModule {
  static {
    this.ɵfac = function UserTrackingModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || UserTrackingModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: UserTrackingModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserTrackingModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [],
      exports: []
    }]
  }], null, null);
})();

/*
 * Public API Surface of user-tracking
 */

/**
 * Generated bundle index. Do not edit.
 */

export { USER_TRACKING_CONFIG, UserTrackingModule, UserTrackingService };
